import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import { Link } from 'gatsby'

import pic11 from '../assets/images/pic11.jpg'

const hidden = {
  display: 'none',
}

const Generic = props => (
  <Layout>
    <Helmet>
      <title>
        Thanh Le College, School of Cosmetology - School Disclosures
      </title>
      <meta name="description" content="BPPE Disclosures" />
    </Helmet>

    <div id="main" className="alt">
      <section id="one">
        <div className="inner">
          <header className="major">
            <h1>School Disclosures</h1>
          </header>
          <span className="image main">
            <img src={pic11} alt="" />
          </span>
          <p>
            Thank you for your interest in our school. On this page you may
            download our student disclosures.
          </p>
          <h2>Enrollment Information:</h2>
          <ul className="actions">
            <li>
              <Link className="fit button special small" to="/school-catalog">
                School Catalog (January 8, 2024 - December 31, 2024)
              </Link>
            </li>
            <li>
              <Link
                className="fit button small"
                to="/school-disclosures/#"
                style={hidden}
              >
                Pre-Enrollment Form
              </Link>
            </li>
            <li>
              <Link className="fit button small" to="/enrollment-application">
                Enrollment Application
              </Link>
            </li>
          </ul>
          <h2>Disclosures and Important Information:</h2>
          <ul className="actions">
            <li>
              <Link className="fit button small" to="/campus-security">
                Campus Security Act Disclosure Statement
              </Link>
            </li>
            <li>
              <Link className="fit button small" to="/approval-disclosure">
                Approval Disclosure Statement
              </Link>
            </li>
            <li>
              <Link className="fit button small" to="/bppe-disclosures">
                BPPE Annual Report 2022
              </Link>
            </li>
            <li>
              <Link className="fit button small" to="/naccas-annual-report">
                2022 NACCAS Annual Report
              </Link>
            </li>
          </ul>
          <h2>BPPE performance fact sheets 2021-2022:</h2>
          <ul className="actions">
            <li>
              <Link
                className="fit button small"
                to="/performance-fact-sheet/performance"
              >
                School Performance Fact Sheet
              </Link>
            </li>
          </ul>
          <h2 style={hidden}>BPPE Disclosures 2020</h2>
          <ul className="actions">
            <li>
              <Link
                className="fit button small"
                to="/bppe-disclosures"
                style={hidden}
              >
                2020 Annual Report
              </Link>
            </li>
          </ul>
        </div>
      </section>
    </div>
  </Layout>
)

export default Generic
